import {useStore} from '../store/index'

import langs from '~/plugins/langs.json'

export function useUtils() {
  function $t(text:string) {
    const store = useStore()
    if (text) {
      var r = langs[store.NOTARIA.Languaje][text]
      return r ? r : text
    }
  }
  function ir(x:any) {
    const store = useStore()
    if (x) {
      this.$router.push(x);
      return true
    } else {
      return false
    }
  }
  function imagenIDtoSRC(imagenID:any) {
    const store = useStore()
    var imagen = store.NOTARIA.Archivos.find((x:any) => x.id === imagenID);
    if (imagen !== undefined) {
      return encodeURI(process.env.NODE_ENV === 'development' ? store.api_url + 'appdata/' + store.NOTARIA.id + '/' + imagen.path : store.api_url + 'appdata/' + imagen.path);
    }

  }

  async function sendMail(formData:any) {
    const store = useStore()
    var cuerpo = "Mensaje enviado desde la web: " + location.origin + "<br><br>";

    if (formData.name != "")
      cuerpo = cuerpo + "Remitente: " + formData.name + "<br><br>";
    if (formData.correo != "")
      cuerpo = cuerpo + 'Correo de contacto: <a href="mailto:' + formData.email + '">' + formData.email + '</a>' + "<br><br>";
    if (formData.phone != "")
      cuerpo = cuerpo + "Teléfono: " + formData.phone + "<br><br>";
    if (formData.asunto != "")
      cuerpo = cuerpo + "Asunto: " + formData.subject + "<br><br>";
    if (formData.message)
      cuerpo = cuerpo + "Mensaje: " + formData.message + "<br><br>";


    var model = {
      "perfil": "info",
      "cuerpo": cuerpo,
      "is_cuerpo_html": true,
      "asunto": "Contacto WEB " + store.NOTARIA.Nombre,
      "destinatarios": [{ "nombre": store.NOTARIA.Nombre, "direccion": store.NOTARIA.Contacto.Email }],
      //"destinatarios": [{"nombre": "Web Notin", "direccion": "modelos@notin.net"}],
      //"destinatarios_cco": [{"nombre": "Web Notin", "direccion": "modelos@notin.net"}],
      //"destinatarios": [{"nombre": store.NOTARIA.Nombre, "direccion": 'modelos@notin.net'}],
      // "adjuntos": [
      //     {"nombre": "hola.txt", "contenido": "aG9sYSBhIHRvZG9zIGFtaWdvcyBkZWwgbXVuZG8gbXVuZGlhbA=="}
      // ]
    }

    try {
      let res:any = await $fetch('https://mailing.notin.es/api/email',{method:"POST",body:model, headers: { 'Content-Type': 'application/json' } })
      if (res.data == "") {
        //enviamos copia de email a 
        model.destinatarios = [{ "nombre": "webNotin", "direccion": "web@notin.net" }],
          res = await $fetch('https://mailing.notin.es/api/email',{method:"post", model, headers: { 'Content-Type': 'application/json' } })
      }
      return res.data;
    } catch (e) {
      //console.log(e)
    }

  }
  return {
    sendMail,
    imagenIDtoSRC,
    $t,
    ir
  }

}

